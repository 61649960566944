/* eslint-disable @typescript-eslint/camelcase */
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { VideoPlayerPage } from '../video-player/video-player-page';


@Component({
    selector: 'app-video-embed-player',
    templateUrl: 'video-player-embed-page.html',
    styleUrls: ['../video-player/video-player-page.scss', 'video-player-embed-page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerEmbedPage extends VideoPlayerPage implements OnInit {
    ngOnInit() {
        // Overwrite the analiytics page ID before super.ngOnInit()
        this.isEmbedded = true;
        this.analyticsPageId = 'video-player-embedded';
        super.ngOnInit();
        this.logger.debug('VideoPlayerEmbedPage ngOnInit()');
        // this.introAnimation = new VideoIntroAnimation(this.constants, this.appData); // disable this as workaround in the meantime
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        // check if come param autostart
        if (this._route.queryParams) {
            this._route.queryParams.subscribe(async params => {
                this.autoStart = params.autostart === 'true';
            });
        }
        // this.appData.showMenu = false;
        // this.startIntroAnimation(); // disable this as workaround in the meantime cause memory leak
    }

    ionViewDidEnter() {
        // Don't allow crawling of the "embed" page
        this.metaService.updateTag({ name: 'robots', content: 'noindex' });
    }

    checkWordCatalogBeforeSave() {
        this.logger.debug('checkWordCatalogBeforeSave - DOING NOTHING');
    }

    onePlayerEnd() {
        this.logger.debug('onePlayerEnd (embedded)');
        // Doing nothing else here.
    }

    onPlayerEnd() {
        this.logger.debug('onPlayerEnd (embedded)');
        this.showPostroll = true;
    }


    downloadClickedWordsFile(data: Blob, type: string, filename?: string) {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        if (filename) {
            a.download = filename;
        }
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    generateQuizletFile(): void {
        const contentArray = this.wordsClicked.map(word => `${word.fromWord || word.oriWord},${word.toWord}`);
        const csvContent = contentArray.join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        this.downloadClickedWordsFile(blob, 'text/csv', 'clicked_words_uugotit.csv');
    }

    toWordShow(toWord: any) {
        let toWords = toWord.split('/');
        toWords = toWords.map(word => word.trim()); // remove leading and trailing spaces
        toWords = toWords.filter(word => !word.startsWith('__START_NO_SHOW__') && !word.endsWith('__END_NO_SHOW__'));
        const toWordShow = toWords.join(' / ');
        return toWordShow;
    }
}
